import React, { useState } from "react";
import "./App.css"; // You can reuse the same CSS styles
import GrandArchiveLogo from "./Grand_Archive_Logo.png";

function CardDrawSimulator() {
  const [totalCards, setTotalCards] = useState(60);
  const [cardTypes, setCardTypes] = useState({
    Ally: 10,
    Draw: 10,
    "Floating Memory": 10,
    Recovery: 10,
    Spell: 10,
    Empower: 10,
  });
  const [results, setResults] = useState(null);

  const handleCardTypeChange = (type, value) => {
    // Ensure the total of card types does not exceed totalCards
    const newCardTypes = {
      ...cardTypes,
      [type]: Math.max(0, Math.min(value, totalCards)),
    };
    const totalTypeCount = Object.values(newCardTypes).reduce(
      (sum, count) => sum + count,
      0
    );

    if (totalTypeCount <= totalCards) {
      setCardTypes(newCardTypes);
    }
  };

  const drawCards = () => {
    const drawnCards = [];
    const cardPool = [];

    // Create the card pool based on cardTypes
    for (const [type, count] of Object.entries(cardTypes)) {
      for (let i = 0; i < count; i++) {
        cardPool.push(type);
      }
    }

    // Shuffle the card pool and draw 7 cards
    for (let i = cardPool.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [cardPool[i], cardPool[j]] = [cardPool[j], cardPool[i]];
    }

    drawnCards.push(...cardPool.slice(0, 7));

    // Count occurrences of each card type in the drawn cards
    const counts = {};
    drawnCards.forEach((card) => {
      counts[card] = (counts[card] || 0) + 1;
    });

    setResults(counts);
  };

  return (
    <div className="CardDrawSimulator">
      <header>
        <img src={GrandArchiveLogo} alt="Logo" className="logo" />
      </header>
      <h2>Card Draw Simulator</h2>

      <label>
        Total Cards in Deck:
        <input
          type="number"
          value={totalCards}
          min="1"
          onChange={(e) => setTotalCards(parseInt(e.target.value, 10))}
        />
      </label>

      {Object.keys(cardTypes).map((type) => (
        <label key={type}>
          {type} Cards:
          <input
            type="number"
            value={cardTypes[type]}
            min="0"
            max={totalCards}
            onChange={(e) =>
              handleCardTypeChange(type, parseInt(e.target.value, 10))
            }
          />
        </label>
      ))}

      <button onClick={drawCards}>Draw 7 Cards</button>

      {results && (
        <div>
          <h3>Draw Results:</h3>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {" "}
            {/* Remove bullets and padding */}
            {Object.entries(results).map(([type, count]) => (
              <li key={type}>
                {type}: {count}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CardDrawSimulator;
