import React, { useState } from "react";
import "./App.css";
import GrandArchiveLogo from "./Grand_Archive_Logo.png";

function App() {
  const [totalCards, setTotalCards] = useState(60);
  const [cardsPulled, setCardsPulled] = useState(7);
  const [appleCards, setAppleCards] = useState(10);
  const [probability, setProbability] = useState(null);
  const [cardType, setCardType] = useState("Ally"); // New state for card type name

  const calculateProbability = () => {
    const nonAppleCards = totalCards - appleCards;
    let probNoApple = 1;
    let numerator = nonAppleCards;
    let denominator = totalCards;

    for (let i = 0; i < cardsPulled; i++) {
      probNoApple *= numerator / denominator;
      numerator--;
      denominator--;
    }

    const probAtLeastOneApple = 1 - probNoApple;
    setProbability(probAtLeastOneApple);
  };

  // Function to get the color based on the percentage
  const getPercentageColor = (percentage) => {
    if (percentage >= 10 && percentage <= 79) {
      return "red";
    } else if (percentage >= 80 && percentage <= 89) {
      return "orange";
    } else if (percentage >= 90) {
      return "green";
    }
    return "black"; // Default color
  };

  return (
    <div className="App">
      <header>
        <img src={GrandArchiveLogo} alt="Logo" className="logo" />
      </header>

      <h1>Card Probability Calculator</h1>

      <label>
        Card Type:
        <input
          type="text"
          value={cardType}
          onChange={(e) => setCardType(e.target.value)} // Change card type text
        />
      </label>

      <label>
        Total Cards in Deck:
        <input
          type="number"
          value={totalCards}
          min="1"
          onChange={(e) => setTotalCards(parseInt(e.target.value, 10))}
        />
      </label>

      <label>
        Number of {cardType} Cards:
        <input
          type="number"
          value={appleCards}
          min="0"
          max={totalCards}
          onChange={(e) => setAppleCards(parseInt(e.target.value, 10))}
        />
      </label>

      <label>
        Cards Pulled:
        <input
          type="number"
          value={cardsPulled}
          min="1"
          max={totalCards}
          onChange={(e) => setCardsPulled(parseInt(e.target.value, 10))}
        />
      </label>

      <button onClick={calculateProbability}>Calculate Probability</button>

      {probability !== null && (
        <p>
          The probability of drawing at least one {cardType} card is:
          <span style={{ color: getPercentageColor(probability * 100) }}>
            {" "}
            {(probability * 100).toFixed(2)}%{" "}
          </span>
        </p>
      )}
    </div>
  );
}

export default App;
