import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import CardProbabilityCalculator from "./CardProbabilityCalculator"; // Existing component
import CardDrawSimulator from "./CardDrawSimulator"; // New component
import "./App.css";
import GrandArchiveLogo from "./Grand_Archive_Logo.png";

function App() {
  const location = useLocation();

  // Function to check if the button should be active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<CardProbabilityCalculator />} />
        <Route path="/card-draw-simulator" element={<CardDrawSimulator />} />
      </Routes>
      <div className="nav-buttons">
        <Link to="/card-draw-simulator">
          <button
            className={`nav-button ${
              isActive("/card-draw-simulator") ? "active" : "inactive"
            }`}
          >
            Card Draw Simulator
          </button>
        </Link>

        <Link to="/">
          <button
            className={`nav-button ${isActive("/") ? "active" : "inactive"}`}
          >
            Card Probability Calculator
          </button>
        </Link>
      </div>
    </div>
  );
}

// Use Router to wrap the App component
export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
